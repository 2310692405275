var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-view',{attrs:{"title":"Типы лицензий","filter":"","create":"","create-title":"Создать тип лицензии","url-create":"/license_type/create","url-update":"/license_type/update/:id","store-module":"license_type","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Код', value: 'code', width: 1 },
        { text: 'Название', value: 'name' },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems},scopedSlots:_vm._u([{key:"filter",fn:function({ filter }){return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность"},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ID","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.id),callback:function ($$v) {_vm.$set(filter, "id", $$v)},expression:"filter.id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Код","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.code),callback:function ($$v) {_vm.$set(filter, "code", $$v)},expression:"filter.code"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Название","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }